import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "./Navbaar&Footer/Navbar";
import Footer from "./Navbaar&Footer/Footer";
import { useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaEdit } from "react-icons/fa";

const VendorDetailst = () => {
  const { vendorId } = useParams();
  const [vendor, setVendor] = useState({ companyName: "", ownerName: "" });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVendorDetails = async () => {
      try {
        console.log(
          `Fetching data from http://localhost:5000/api/vendor/${vendorId}`
        );
        const response = await axios.get(
          `http://localhost:5000/api/vendor/${vendorId}`
        );
        setVendor(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVendorDetails();
  }, [vendorId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <Navbar />
      <header className="flex justify-between items-center bg-primary p-4 text-white">
        <div className="text-lg font-bold">Logo</div>
      </header>

      <div
        className="relative w-full h-64 bg-cover bg-center"
        style={{
          backgroundImage:
            "url(https://placehold.co/150x150?text=Background+Picture)",
        }}
      >
        <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-black to-transparent text-white flex justify-between items-end">
          <div className="flex items-center pl-2">
            <img
              src={
                vendor.image ||
                "https://placehold.co/150x150?text=Profile+Picture"
              }
              alt="User Profile"
              className="w-24 h-24 rounded-full border-4 border-white shadow-lg mr-6"
            />
            <FaEdit
            // className='p-0 m-0 left-32 absolute cursor-pointer'
            // onClick={() => setShowInput(!showInput)} // Toggle file input
            />

            {/* {showInput && (
              <div className="absolute left-32">
                <input type="file" onChange={handleImageChange} />
                <button onClick={handleUpload} className="bg-blue-500 text-white p-1 rounded mt-2">Upload</button>
              </div>
            )} */}

            <div>
              <h2 className="text-2xl font-semibold">
                {vendor.ownerName || "Owner Name"}
              </h2>
              <p className="text-gray-300">
                {vendor.companyName || "Vendor Name"}
              </p>
              <p className="text-gray-300">{vendor.panGst || "Address"}</p>
              <button className="bg-red-600 mt-1 p-1 rounded-lg">
                Message
              </button>
            </div>
          </div>

          <div className="bg-white text-black p-4 shadow-lg rounded-lg flex flex-col items-center">
            <h2 className="text-lg font-semibold">Score:</h2>
            <p className="text-primary text-xl">4.8</p>
            <p className="text-muted-foreground">10 Reviews</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default VendorDetailst;
