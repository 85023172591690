import React from "react";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";

const About = () => {
  return (
    <div>
      <Navbar />
      <div className="max-w-6xl mx-auto bg-white rounded-lg shadow-xl p-10 mt-24 mb-20">
        <h1 className="font-bold text-center text-4xl mb-10 text-gray-800">
          About Us
        </h1>
        
   
          <div className=" text-xl text-center text-gray-700 leading-relaxed">
            <p>
              Procydia is an organization dedicated to driving the growth of
              India's startup ecosystem. We provide best-in-class digital and
              business services for businesses at all scales. Whether you want to
              start a business or expand an existing one, Procydia is the right
              destination for you.
            </p>
            <p className="mt-6">
              Our mission is to empower entrepreneurs and businesses of all sizes
              by offering top-tier services tailored to their unique needs. With a
              commitment to innovation, excellence, and customer satisfaction, we
              provide the tools, resources, and expertise necessary to turn your
              business vision into reality. Choose Procydia, where your business
              aspirations meet unparalleled support and growth opportunities.
            </p>
          </div>
  
        
   

          <div className="text-center text-xl text-gray-700 leading-relaxed">
            <p>
              Procydia is your trusted partner on the path to success. Whether you're
              looking to launch a new venture or scale an existing enterprise, we are
              committed to providing unparalleled support and growth opportunities.

            </p>
            <p className="mt-6">
            Our innovative approach and dedication to excellence ensure that your
            business not only survives but thrives in today's competitive landscape.
            </p>
          </div>
  
        
        <div className="flex flex-col sm:flex-row justify-center items-center gap-6 mt-12">
          <a
            href="/contact"
            className="bg-red-500 rounded-full px-8 py-4 text-white font-bold hover:bg-red-600 transition duration-300 shadow-md"
          >
            Contact Us Now!
          </a>
          <a
            href="tel:9818347406"
            className="bg-blue-500 rounded-full px-8 py-4 text-white font-bold hover:bg-blue-600 transition duration-300 shadow-md"
          >
            Call Us Now!
          </a>
        </div>
      </div>
      <Footer className="mt-12" />
    </div>
  );
};

export default About;
