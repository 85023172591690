import React from "react";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";

const CateringServicePlan = () => {
  return (
    <>
      <div>
        <Navbar />
      </div>
      <div className="p-10 mt-28 mb-10 max-w-7xl mx-auto bg-white border border-gray-200 rounded-lg shadow-lg space-y-20">
      <h1 className="text-4xl font-extrabold text-center text-gray-900 mb-12">
  Plan a Catering Service
</h1>

{/* Budget Section */}
<section className="flex flex-col md:flex-row items-center md:items-start space-y-6 md:space-y-0">
  <div className="md:ml-10">
    <h2 className="text-3xl font-bold text-gray-800 mb-6">Budget</h2>
    <ul className="list-disc pl-6 space-y-4 text-gray-700">
      <li>
        <strong>Total Cost:</strong> Establish your overall budget for the catering service, including all associated costs such as food, beverages, staffing, and equipment rentals.
      </li>
      <li>
        <strong>Pricing Structures:</strong> Understand the caterer's pricing model—whether it's per person, per dish, or a package deal. Compare different structures to find one that fits your event.
      </li>
      <li>
        <strong>Additional Costs:</strong> Be aware of any hidden fees, such as service charges, travel fees, overtime for staff, or charges for special requests like custom dishes or last-minute changes.
      </li>
      <li>
        <strong>Payment Terms:</strong> Clarify the payment schedule, including deposits and final payments. Make sure you know the refund and cancellation policy.
      </li>
    </ul>
  </div>
</section>

{/* Menu Options Section */}
<section className="flex flex-col md:flex-row items-center md:items-start space-y-6 md:space-y-0">
  <div className="md:ml-10">
    <h2 className="text-3xl font-bold text-gray-800 mb-6">
      Menu Options
    </h2>
    <ul className="list-disc pl-6 space-y-4 text-gray-700">
      <li>
        <strong>Diverse Selection:</strong> Ensure the caterer offers a wide variety of menu options that accommodate dietary restrictions, allergies, and cultural preferences (e.g., vegetarian, vegan, gluten-free, kosher, or halal).
      </li>
      <li>
        <strong>Customization:</strong> Confirm that the menu can be customized to match your event's theme, guest preferences, and seasonal availability of ingredients. This could include personalized dishes, signature drinks, or themed cuisine.
      </li>
      <li>
        <strong>Menu Tasting:</strong> Schedule a tasting session with your caterer to sample key dishes, ensuring they meet your expectations for taste, presentation, and quality before finalizing the menu.
      </li>
      <li>
        <strong>Beverage Options:</strong> Decide whether the catering service will include beverages such as soft drinks, alcohol, or a custom bar service, and clarify pricing for each option.
      </li>
    </ul>
  </div>
</section>

{/* Service Style Section */}
<section className="flex flex-col md:flex-row items-center md:items-start space-y-6 md:space-y-0">
  <div className="md:ml-10">
    <h2 className="text-3xl font-bold text-gray-800 mb-6">
      Service Style
    </h2>
    <ul className="list-disc pl-6 space-y-4 text-gray-700">
      <li>
        <strong>Buffet vs. Plated:</strong> Decide whether you prefer buffet-style service, where guests help themselves, or plated service, where meals are served to seated guests. This decision can impact the cost and flow of the event.
      </li>
      <li>
        <strong>Staffing Needs:</strong> Ensure the caterer provides adequate staff for the service style you've chosen. Consider the need for servers, bartenders, and cleaning staff to ensure smooth service throughout the event.
      </li>
      <li>
        <strong>Food Stations:</strong> Explore the option of interactive food stations, such as carving stations or build-your-own salad bars, to create a unique dining experience.
      </li>
      <li>
        <strong>Self-Serve vs. Waitstaff:</strong> Depending on your event’s formality, decide if you want a full-service experience with waitstaff or a more casual self-serve approach.
      </li>
    </ul>
  </div>
</section>

{/* Event Logistics Section */}
<section className="flex flex-col md:flex-row items-center md:items-start space-y-6 md:space-y-0">
  <div className="md:ml-10">
    <h2 className="text-3xl font-bold text-gray-800 mb-6">
      Event Logistics
    </h2>
    <ul className="list-disc pl-6 space-y-4 text-gray-700">
      <li>
        <strong>Venue Coordination:</strong> Ensure that your caterer can work seamlessly with your chosen venue, coordinating details such as kitchen access, timing, and venue-specific restrictions (e.g., outdoor cooking limitations).
      </li>
      <li>
        <strong>Timing & Schedule:</strong> Work with your caterer to establish a timeline for food service, including when appetizers, main courses, and desserts will be served, to ensure smooth flow of the event.
      </li>
      <li>
        <strong>Tableware & Equipment:</strong> Confirm whether the caterer provides tableware, linens, glassware, and equipment, or if these items need to be rented separately.
      </li>
      <li>
        <strong>Cleanup & Breakdown:</strong> Clarify whether the catering service includes cleanup and breakdown of the dining area after the event, and any associated costs for these services.
      </li>
    </ul>
  </div>
</section>

{/* Dietary Restrictions Section */}
<section className="flex flex-col md:flex-row items-center md:items-start space-y-6 md:space-y-0">
  <div className="md:ml-10">
    <h2 className="text-3xl font-bold text-gray-800 mb-6">
      Dietary Restrictions
    </h2>
    <ul className="list-disc pl-6 space-y-4 text-gray-700">
      <li>
        <strong>Allergy Accommodations:</strong> Ensure the caterer is informed of any food allergies among guests and can offer safe alternatives or precautions to avoid cross-contamination.
      </li>
      <li>
        <strong>Special Diets:</strong> Plan for guests with specific dietary needs, such as gluten-free, dairy-free, vegan, or religious requirements, and confirm that the caterer can accommodate these requests.
      </li>
      <li>
        <strong>Labeling & Signage:</strong> If you are offering a buffet or multiple dishes, consider providing labels for each dish to indicate ingredients and any allergens.
      </li>
    </ul>
  </div>
</section>


        {/* Continue with the rest of the sections similarly */}
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default CateringServicePlan;
