import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";

const ConstructionVendors = () => {
  const [industryLeaders, setIndustryLeaders] = useState([]);

  // useCallback to memoize the fetchData function and prevent unnecessary re-creations
  const fetchData = useCallback(async () => {
    try {
      // Simulating an API call with async/await
      const response = await new Promise((resolve) =>
        setTimeout(() => {
          resolve([
            { id: 1, name: "Home Construction", photo: "home.jpg", url: "/construction", contact: "Contact us at +91-9818347406" },
            { id: 2, name: "Building Construction", photo: "building.jpg", url: "/construction", contact: "Contact us at +91-9818347406" },
            { id: 3, name: "Residential Construction", photo: "residential.jpg",url: "/construction", contact: "Contact us at +91-9818347406" },
            { id: 4, name: "Apartment Construction", photo: "apartments.jpg", url: "/construction", contact: "Contact us at +91-9818347406" },
            { id: 5, name: "Commercial Construction", photo: "commercial.jpg", url: "/construction", contact: "Contact us at +91-9818347406" },
            { id: 6, name: "Industrial Construction", photo: "indusrty.jpg", url: "/construction", contact: "Contact us at +91-9818347406" },
          ]);
        }, 500) // Reduced delay for faster loading
      );

      setIndustryLeaders(response);
    } catch (error) {
      console.error("Error fetching industry leaders:", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
        <Navbar />
    <div className="bg-white text-black p-10 w-full mt-12 mb-32">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold">Explore Construction Vendors</h2>
        <p className="text-gray-600">Choose from the top categories</p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {industryLeaders.length > 0 ? (
          industryLeaders.map((leader) => (
            <Link
              key={leader.id}
              to={leader.url}
              className="block overflow-hidden shadow-lg transform transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-xl"
            >
              <img
                src={leader.photo}
                alt={leader.name}
                className="w-full h-60 object-cover rounded-md"
                loading="lazy" // Use lazy loading for images
              />
              <div className="p-4 bg-slate-100">
                <h3 className="text-lg font-bold text-black">{leader.name}</h3>
                <p className="text-sm text-gray-600">{leader.subName}</p>
                <p className="text-sm text-gray-500 mt-2">{leader.contact}</p>
              </div>
            </Link>
          ))
        ) : (
          <p className="text-sm text-gray-500 text-center m-52">
            Loading categories...
          </p>
        )}
      </div>
    </div>
    <Footer />
    </div>
  );
};

export default React.memo(ConstructionVendors); // Memoize the component to prevent unnecessary re-renders
