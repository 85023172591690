import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { gsap } from "gsap";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";

const Decoration = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    eventDescription: "",
    eventDate: "",
  });

  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (showPopup) {
      gsap.fromTo(
        ".popup-container",
        { scale: 0 },
        { scale: 1, duration: 0.5, ease: "elastic.out(1, 0.3)" }
      );
      gsap.to(".tick-icon", { rotation: 360, duration: 1, ease: "power4.out" });

      setTimeout(() => {
        setShowPopup(false);
        navigate("/");
      }, 5000); // 5 seconds delay
    }
  }, [showPopup, navigate]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://procydia.onrender.com/api/decoration/create",
        formData
      );

      if (response.status === 201) {
        setShowPopup(true);
      }
    } catch (error) {
      console.error("There was an error submitting the form!", error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="bg-white text-black p-4 sm:p-6 lg:p-10 w-full mt-8 flex flex-col lg:flex-row justify-center">
        {/* <div className="lg:w-3/5">
        <h1 className="text-4xl font-bold text-center mb-8">Selecting the Perfect Decorations for Your Event</h1>
        <div className="max-w-4xl mx-auto">
        <p className="text-lg mb-6">
            Decorations set the mood and atmosphere for your event. They reflect the theme and style, making your event
            visually appealing and memorable. Here are some important factors to consider when choosing decorations.
          </p>

          <h2 className="text-2xl font-bold mb-4">1. Event Theme and Style</h2>
          <p className="text-lg mb-6">
            Your decorations should align with the overall theme and style of the event. Whether it's a rustic wedding,
            a modern corporate event, or a vibrant birthday party, the decor should enhance and reflect your chosen theme.
          </p>

          <h2 className="text-2xl font-bold mb-4">2. Budget</h2>
          <p className="text-lg mb-6">
            Set a clear budget for decorations. High-quality decor can be costly, so it’s important to allocate funds
            wisely. Consider DIY options or rental services to save on costs while still achieving a stunning look.
          </p>

          <h2 className="text-2xl font-bold mb-4">3. Venue Requirements</h2>
          <p className="text-lg mb-6">
            Check if your venue has any restrictions or requirements regarding decorations. Some venues may not allow
            certain types of decor, such as open flames or hanging items from the ceiling, so plan accordingly.
          </p>

          <h2 className="text-2xl font-bold mb-4">4. Color Scheme</h2>
          <p className="text-lg mb-6">
            The color scheme should complement the theme and style of your event. Coordinate with other elements like
            table settings, flowers, and lighting to create a cohesive and visually pleasing environment.
          </p>

          <h2 className="text-2xl font-bold mb-4">5. Floral Arrangements</h2>
          <p className="text-lg mb-6">
            Flowers are a classic element of decoration. Consider the seasonality, color, and type of flowers when planning
            your floral arrangements. Fresh flowers can add elegance and a natural touch to your event.
          </p>

          <h2 className="text-2xl font-bold mb-4">6. Lighting</h2>
          <p className="text-lg mb-6">
            Lighting plays a crucial role in setting the mood. Whether it's soft, romantic lighting for a wedding or
            vibrant, colorful lights for a party, the right lighting can transform your event space.
          </p>
          </div>
        </div> */}
        <div className="lg:w-2/5 p-4 sm:p-6 lg:p-8 mt-6 lg:mt-0">
          <h2 className="text-2xl font-bold mb-6 text-center">
            Book Your Decoration Service Now
          </h2>
          <p className="text-center text-gray-600 mb-4">
            We’re excited to make your event unforgettable! Please fill out the
            details below, and we’ll get back to you soon.
          </p>
          <form
            onSubmit={handleSubmit}
            className="space-y-6 bg-gray-100 p-6 sm:p-8 rounded-lg shadow-lg"
          >
            <div>
              <label className="block mb-2 text-lg font-medium">Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder="Enter your full name"
                className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <div>
              <label className="block mb-2 text-lg font-medium">Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Enter your email address"
                className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <div>
              <label className="block mb-2 text-lg font-medium">Phone:</label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                placeholder="Enter your phone number"
                className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <div>
              <label className="block mb-2 text-lg font-medium">
                Event Description:
              </label>
              <textarea
                name="eventDescription"
                value={formData.eventDescription}
                onChange={handleChange}
                required
                placeholder="Provide a brief description of your event"
                className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <div>
              <label className="block mb-2 text-lg font-medium">
                Date of Event:
              </label>
              <input
                type="date"
                name="eventDate"
                value={formData.eventDate}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-3 rounded-md font-semibold hover:bg-blue-700 transition-all shadow-md"
            >
              Submit
            </button>
          </form>
        </div>
      </div>

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="popup-container bg-white p-8 rounded-lg text-center shadow-2xl">
            <div className="flex justify-center mb-4">
              <svg
                className="tick-icon w-20 h-20 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <h1 className="text-2xl font-bold text-gray-800">
              Details Submitted Successfully!
            </h1>
            <h2 className="text-xl font-bold text-gray-800">
              We will reach you shortly.
            </h2>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Decoration;
