import React, { useState, useEffect, useCallback, Suspense } from "react";
import { Link } from "react-router-dom";

const ShopByDestination = () => {
  const [industryLeaders, setIndustryLeaders] = useState([]);
  const [loading, setLoading] = useState(true); // Add a loading state

  // useCallback to memoize the fetchData function and prevent unnecessary re-creations
  const fetchData = useCallback(async () => {
    try {
      const response = await new Promise((resolve) =>
        setTimeout(() => {
          resolve([
            { id: 1, name: "M-BLock Market", subName: "Greater Kailash-1, Kailash Colony, Greater Kailash, New Delhi, Delhi 110001", photo: "GREATER-KAILASH.jpg", url: "/mblockmkt", contact: "Contact us at +91-9818347406" },
            { id: 2, name: "Lajpat Nagar Market", subName: "Block J, Lajpat Nagar II, Lajpat Nagar, New Delhi, Delhi 110024", photo: "Lajpatnagar.avif", url: "/lajpatmkt", contact: "Contact us at +91-9818347406" },
            { id: 3, name: "Khan Market", subName: "Rabindra Nagar, New Delhi, Delhi 110003", photo: "khan-market.jpg", url: "/khanmkt", contact: "Contact us at +91-9818347406" },
            { id: 4, name: "Sarojini Market", subName: "Sarojini Nagar Market, Sarojini Nagar, New Delhi, Delhi 110023", photo: "Sarojini.jpg", url: "/sarojinimkt", contact: "Contact us at +91-9818347406" },
            { id: 5, name: "Rajouri Garden Market", subName: "Rajouri Garden, New Delhi, Delhi, 110027", photo: "Rajouri Garden Market.jpg", url: "/rajourigardenmarket", contact: "Contact us at +91-9818347406" },
            { id: 6, name: "Chandni Chowk Market", subName: "Sis Ganj Guru Dwara, Chhippy Wada, Chandni Chowk, Delhi, 110006" , photo: "CHANDANICHAWK.jpeg" , url: "/chandnichowkmkt", contact: "Contact us at +91-9818347406" }
          ]);
        }, 300) // Shorten delay for faster loading
      );

      setIndustryLeaders(response);
      setLoading(false); // Stop loading once data is fetched
    } catch (error) {
      console.error("Error fetching industry leaders:", error);
      setLoading(false); // Stop loading even on error
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Avoid re-renders by memoizing mapped items
  const categoryCards = useCallback(() => {
    return industryLeaders.map((leader) => (
      <Link
        key={leader.id}
        to={leader.url}
        className="block overflow-hidden shadow-lg transform transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-xl"
      >
        <img
          src={leader.photo}
          alt={leader.name}
          className="w-full h-60 object-cover rounded-md"
          loading="lazy" // Keep lazy loading for images
        />
        <div className="p-4 bg-slate-100">
          <h3 className="text-lg font-bold text-black">{leader.name}</h3>
          <p className="text-sm text-gray-600">{leader.subName}</p>
          <p className="text-sm text-gray-500 mt-2">{leader.contact}</p>
        </div>
      </Link>
    ));
  }, [industryLeaders]);

  return (
    <div className="bg-white text-black p-10 w-full mt-0">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold">Explore Markets Near You</h2>
        <p className="text-gray-600">Choose from the top categories
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {loading ? (
          <p className="text-sm text-gray-500 text-center">Loading categories...</p>
        ) : (
          categoryCards() // Use the memoized component rendering
        )}
      </div>
    </div>
  );
};

export default React.memo(ShopByDestination);
