import React, { useState } from "react";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";
import axios from "axios";
import { gsap } from "gsap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles

const djList = [
  {
    id: 1,
    name: "DJ Mike",
    subname: "Party Specialist",
    image: "DJ Mike.jpeg",
    profileLink: "http://localhost:3000/profile/66cc6a467765c256351abe15",
    contactNumber: "+1234567890",
  },
  {
    id: 2,
    name: "DJ Sarah",
    subname: "Wedding Expert",
    image: "DJ Sarah.jpg",
    profileLink: "/dj-sarah-profile",
    contactNumber: "+0987654321",
  },
  {
    id: 3,
    name: "DJ Alex",
    subname: "Corporate Events",
    image: "DJ Alex.jpg",
    profileLink: "/dj-alex-profile",
    contactNumber: "+1122334455",
  },
  {
    id: 4,
    name: "DJ Emma",
    subname: "Music Festival Pro",
    image: "DJ Sam.jpg",
    profileLink: "/dj-emma-profile",
    contactNumber: "+9988776655",
  },
];

const Entertainment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    eventDescription: "",
    eventDate: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        "https://procydia.onrender.com/api/entertainment/create",
        formData
      );
      setFormSubmitted(true);
      gsap.fromTo(
        ".popup-container",
        { opacity: 0, y: -50 },
        { opacity: 1, y: 0, duration: 0.5, ease: "power3.out" }
      );
      setTimeout(() => {
        window.location.href = "/";
      }, 5000);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="bg-white text-black p-4 sm:p-6 lg:p-10 w-full mt-8 flex flex-col lg:flex-row">
        {/* Form Section */}
        <div className="lg:w-2/5 p-6 lg:p-14 bg-gray-100 rounded-lg shadow-lg mt-6 lg:mt-0">
          <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center">
            Book Your Entertainment Service Now
          </h2>
          <form
            onSubmit={handleSubmit}
            className="space-y-4 bg-white p-6 rounded-lg shadow-md"
          >
            <div>
              <label
                className="block text-gray-700 text-lg font-bold mb-2"
                htmlFor="name"
              >
                Name
              </label>
              <input
                className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-200"
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>

            <div>
              <label
                className="block text-gray-700 text-lg font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-200"
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email address"
                required
              />
            </div>

            <div>
              <label
                className="block text-gray-700 text-lg font-bold mb-2"
                htmlFor="phone"
              >
                Phone
              </label>
              <input
                className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-200"
                type="tel"
                name="phone"
                id="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Enter your phone number"
                required
              />
            </div>

            <div>
              <label
                className="block text-gray-700 text-lg font-bold mb-2"
                htmlFor="eventDescription"
              >
                Event Description
              </label>
              <textarea
                className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-200"
                name="eventDescription"
                id="eventDescription"
                value={formData.eventDescription}
                onChange={handleChange}
                placeholder="Describe your event"
                required
              />
            </div>

            <div>
              <label
                className="block text-gray-700 text-lg font-bold mb-2"
                htmlFor="eventDate"
              >
                Event Date
              </label>
              <input
                className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-200"
                type="date"
                name="eventDate"
                id="eventDate"
                value={formData.eventDate}
                onChange={handleChange}
                required
              />
            </div>

            <button
              type="submit"
              className="w-full bg-blue-600 text-white font-semibold py-3 rounded-md hover:bg-blue-700 transition duration-300"
            >
              Submit
            </button>
          </form>

          {formSubmitted && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="popup-container bg-white p-8 rounded-lg text-center shadow-2xl">
                <div className="flex justify-center mb-4">
                  <svg
                    className="tick-icon w-20 h-20 text-green-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <h1 className="text-2xl font-bold text-gray-800">
                  Details Submitted Successfully!
                </h1>
                <h2 className="text-xl font-bold text-gray-800">
                  We will reach you shortly.
                </h2>
              </div>
            </div>
          )}
        </div>

        {/* DJ Cards Section */}
        <div className="lg:w-3/5 p-6 lg:p-14">
          <h1 className="text-3xl font-bold text-center mb-8">
            Entertainment DJ Services
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {djList.map((dj) => (
              <div
                key={dj.id}
                className="bg-white shadow-lg rounded-lg overflow-hidden"
              >
                <img
                  src={dj.image}
                  alt={dj.name}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <h2 className="text-2xl font-semibold">{dj.name}</h2>
                  <p className="text-gray-600">{dj.subname}</p>
                  <div className="mt-4 flex justify-between items-center">
                    <a
                      href={`tel:${dj.contactNumber}`}
                      className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition"
                    >
                      Contact Now
                    </a>
                    <a
                      href={dj.profileLink}
                      className="text-blue-500 border border-blue-500 py-2 px-4 rounded-md hover:bg-blue-100 transition"
                    >
                      View Profile
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Entertainment;
