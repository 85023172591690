import React from "react";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";

const Delhi = () => {
  return (
    <div className="p-0 m-0">
      <Navbar />

      <div className="relative overflow-x-auto sm:rounded-lg m-10 mx-auto  p-20">
        <h1 className="p-8 font-bold text-center centre text-3xl">
          TOP VENUES IN DELHI
        </h1>
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Venue
              </th>
              <th scope="col" className="px-6 py-3">
                Address
              </th>
              <th scope="col" className="px-6 py-3">
                Capacity
              </th>
              <th scope="col" className="px-6 py-3">
                Price
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="odd:bg-white even:bg-gray-50 dark:odd:bg-gray-900 dark:even:bg-gray-800 border-b dark:border-gray-700">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                <a href="/profile/" className="hover:underline ">
                  The Grand Ballroom
                </a>
              </th>
              <td className="px-6 py-4">Vasant Kunj, New Delhi</td>
              <td className="px-6 py-4">1000</td>
              <td className="px-6 py-4">₹2,50,000</td>
            </tr>
            <tr className="odd:bg-white even:bg-gray-50 dark:odd:bg-gray-900 dark:even:bg-gray-800 border-b dark:border-gray-700">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Taj Palace
              </th>
              <td className="px-6 py-4">Chanakyapuri, New Delhi</td>
              <td className="px-6 py-4">1200</td>
              <td className="px-6 py-4">₹3,00,000</td>
            </tr>
            <tr className="odd:bg-white even:bg-gray-50 dark:odd:bg-gray-900 dark:even:bg-gray-800 border-b dark:border-gray-700">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                JW Marriott
              </th>
              <td className="px-6 py-4">Aerocity, New Delhi</td>
              <td className="px-6 py-4">1500</td>
              <td className="px-6 py-4">₹3,50,000</td>
            </tr>
            <tr className="odd:bg-white even:bg-gray-50 dark:odd:bg-gray-900 dark:even:bg-gray-800 border-b dark:border-gray-700">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                The Leela Palace
              </th>
              <td className="px-6 py-4">Chanakyapuri, New Delhi</td>
              <td className="px-6 py-4">900</td>
              <td className="px-6 py-4">₹2,80,000</td>
            </tr>
            <tr className="odd:bg-white even:bg-gray-50 dark:odd:bg-gray-900 dark:even:bg-gray-800">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                The Oberoi
              </th>
              <td className="px-6 py-4">Dr. Zakir Hussain Marg, New Delhi</td>
              <td className="px-6 py-4">800</td>
              <td className="px-6 py-4">₹2,00,000</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Footer />
    </div>
  );
};

export default Delhi;
