import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import Footer from "../Navbaar&Footer/Footer";
import Navbar from "../Navbaar&Footer/Navbar";
import axios from "axios";

const Construction = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [date, setDate] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [query, setQuery] = useState(""); // New state for the query field
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false); // State to manage pop-up
    const [error, setError] = useState(""); // State to manage error messages
    const navigate = useNavigate(); // Hook for navigation

    const images = [
        { src: "house.jpeg", label: "House Construction" },
        { src: "building.jpeg", label: "Building Construction" },
        { src: "apartments.jpeg", label: "Apartment Construction" },
        { src: "residential.jpeg", label: "Residential Construction" },
        { src: "buildingcontracts.jpeg", label: "Building Contracts" }
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation check for image selection
        if (!selectedLabel) {
            alert("Please select an image.");
            return;
        }

        const formData = {
            name,
            email,
            date,
            phoneNumber,
            query, // Include the new query field in the form data
            selectedLabel,
        };

        try {
            const response = await axios.post(
                "https://procydia.onrender.com/api/construction/submit",
                formData
            );
            console.log("Form submitted successfully:", response.data);

            // Reset error if it was set previously
            setError("");

            // Show pop-up on successful submission
            setIsSubmitted(true);

            // Redirect to the home page after 3 seconds
            setTimeout(() => {
                navigate("/"); // Redirect to home page
            }, 3000);
        } catch (error) {
            console.error("Error submitting form:", error.response || error.message);

            // Set error state to show an error message
            setError("There was an issue submitting the form. Please try again.");
        }
    };

    return (
        <div>
            <Navbar />
            <div className="max-w-lg mx-auto mt-28 mb-10 p-6 bg-white rounded-lg shadow-lg">
                <h2 className="text-2xl font-semibold text-center mb-6 text-gray-800">Submit Details</h2>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="flex flex-col">
                        <label htmlFor="name" className="mb-2 font-medium text-gray-700">Name:</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter your name"
                            required
                            className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="email" className="mb-2 font-medium text-gray-700">Email:</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                            className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="date" className="mb-2 font-medium text-gray-700">Date:</label>
                        <input
                            type="date"
                            id="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            required
                            className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="phoneNumber" className="mb-2 font-medium text-gray-700">Phone Number:</label>
                        <input
                            type="tel"
                            id="phoneNumber"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="Enter your phone number"
                            required
                            className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    {/* New Query Field */}
                    <div className="flex flex-col">
                        <label htmlFor="query" className="mb-2 font-medium text-gray-700">Your Query:</label>
                        <textarea
                            id="query"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder="Enter your query or additional details"
                            required
                            className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        ></textarea>
                    </div>
                    <div className="flex flex-col">
                        <label className="mb-2 font-medium text-gray-700">Select a Category:</label>
                        <div className="flex justify-between mt-2">
                            {images.map((img, index) => (
                                <div key={index} className="text-center justify-center gap-5">
                                    <img
                                        src={img.src}
                                        alt={`Option ${index + 1}`}
                                        className={`w-20 h-20 object-cover rounded-lg cursor-pointer border-2 transition-transform transform hover:scale-105 ${selectedLabel === img.label ? "border-blue-500" : "border-transparent"}`}
                                        onClick={() => setSelectedLabel(img.label)}
                                    />
                                    <p className="mt-2 text-sm text-gray-600">{img.label}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <button type="submit" className="w-full py-3 bg-blue-500 text-white font-medium rounded-md hover:bg-blue-600 transition-colors">
                        Submit
                    </button>
                </form>

                {/* Error Message */}
                {error && (
                    <div className="mt-4 p-4 bg-red-100 text-red-600 rounded-md">
                        <p>{error}</p>
                    </div>
                )}
            </div>

            {/* Pop-up for successful submission */}
            {isSubmitted && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="popup-container bg-white p-8 rounded-lg text-center shadow-2xl">
              <div className="flex justify-center mb-4">
                <svg
                  className="tick-icon w-20 h-20 text-green-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <h1 className="text-2xl font-bold text-gray-800">
                Details Submitted Successfully!
              </h1>
              <h2 className="text-xl font-bold text-gray-800">
                We will reach out to you shortly.
              </h2>
            </div>
          </div>
        )}
            <Footer />
        </div>
    );
}

export default Construction;
