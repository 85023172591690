import React, { useState } from "react";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";

const PaymentForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    cardNumber: "",
    expiry: "",
    cvv: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Simulate form submission (replace with actual payment processing logic)
    console.log("Payment Details:", formData);
    alert("Payment submitted successfully!");
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <div className="max-w-md mx-auto mt-12 flex flex-col items-center">
        {/* <img
          src="https://img.freepik.com/free-photo/wooden-podium-display-product-stand-winner-pedestal-with-leaf-showcase-product-space-presentation-wood-tree-nature-concept-background-3d-rendering_56104-1729.jpg?t=st=1723184121~exp=1723187721~hmac=ae44e5daab81a178e127a262b22d1fb314dd6b527197288b6e703ac07f74592d&w=740"
          className="w-1/2 mb-8"
          alt="Product display"
        /> */}
        <div className="w-full px-4 mt-10">
          <h2 className="text-3xl font-bold mb-5">Card Details</h2>
          <form
            onSubmit={handleSubmit}
            className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-20"
          >
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="name"
              >
                Name on Card
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="John Doe"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="cardNumber"
              >
                Card Number
              </label>
              <input
                type="text"
                id="cardNumber"
                name="cardNumber"
                value={formData.cardNumber}
                onChange={handleChange}
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="1234 5678 9012 3456"
              />
            </div>
            <div className="flex mb-4">
              <div className="w-1/2 pr-2">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="expiry"
                >
                  Expiry Date
                </label>
                <input
                  type="text"
                  id="expiry"
                  name="expiry"
                  value={formData.expiry}
                  onChange={handleChange}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="MM/YY"
                />
              </div>
              <div className="w-1/2 pl-2">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="cvv"
                >
                  CVV
                </label>
                <input
                  type="text"
                  id="cvv"
                  name="cvv"
                  value={formData.cvv}
                  onChange={handleChange}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="123"
                />
              </div>
            </div>

            <div className="flex items-center mb-4">
              <input type="checkbox" id="terms" className="mr-2" required />
              <label htmlFor="terms" className="text-sm text-gray-700">
                I accept the Terms and Privacy Policy
              </label>
            </div>
            <button
              type="submit"
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 mt-4 rounded focus:outline-none focus:shadow-outline w-full"
            >
              Confirm Payment
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentForm;
