import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { gsap } from "gsap";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";

const Catering = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    eventDescription: "",
    eventDate: "",
  });

  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (showPopup) {
      gsap.fromTo(
        ".popup-container",
        { scale: 0 },
        { scale: 1, duration: 0.5, ease: "elastic.out(1, 0.3)" }
      );
      gsap.to(".tick-icon", { rotation: 360, duration: 1, ease: "power4.out" });

      setTimeout(() => {
        setShowPopup(false);
        navigate("/");
      }, 5000); // 5 seconds delay
    }
  }, [showPopup, navigate]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://procydia.onrender.com/api/catering",
        formData
      );

      if (response.status === 201) {
        setShowPopup(true);
      }
    } catch (error) {
      console.error("There was an error submitting the form!", error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="bg-white text-black p-4 sm:p-6 lg:p-10 w-full mt-8 justify-center flex flex-col lg:flex-row">
        {/* <div className="lg:w-3/5">
          <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-center mb-6 lg:mb-8">
          Choosing the Right Catering Service for Your Event
          </h1>
          <div className="max-w-4xl mx-auto">
          <p className="mb-6">
            Catering is one of the most critical aspects of any event. The food and service can make or break the
            experience for your guests. When selecting a catering service, there are several key factors you should
            consider to ensure your event is a success.
          </p>

          <h2 className="text-2xl font-bold mb-4">1. Experience and Reputation</h2>
          <p className="mb-6">
            Look for a catering service with a strong track record and positive reviews. Experienced caterers are more
            likely to handle your event smoothly and provide high-quality food and service.
          </p>

          <h2 className="text-2xl font-bold mb-4">2. Menu Options and Flexibility</h2>
          <p className="mb-6">
            Ensure the caterer offers a wide range of menu options and is flexible enough to accommodate dietary
            restrictions and preferences. The ability to customize your menu is a significant advantage.
          </p>

          <h2 className="text-2xl font-bold mb-4">3. Tasting Sessions</h2>
          <p className="mb-6">
            A reputable catering service should offer tasting sessions so you can sample their food before making a
            decision. This is an excellent opportunity to assess the quality and presentation of the dishes.
          </p>

          <h2 className="text-2xl font-bold mb-4">4. Service Quality</h2>
          <p className="mb-6">
            The quality of service is just as important as the quality of food. Professional, attentive staff can
            greatly enhance the experience for your guests.
          </p>

          <h2 className="text-2xl font-bold mb-4">5. Budget and Pricing</h2>
          <p className="mb-6">
            Be clear about your budget from the start and make sure the catering service is transparent about their
            pricing. Get detailed quotes to avoid any surprises on the day of the event.
          </p>

          <h2 className="text-2xl font-bold mb-4">6. Reviews and References</h2>
          <p className="mb-6">
            Check online reviews and ask for references to get an idea of the caterer’s reliability and quality. Word of
            mouth recommendations can also be incredibly valuable.
          </p>

          <h2 className="text-2xl font-bold mb-4">7. Additional Services</h2>
          <p className="mb-6">
            Some caterers offer additional services such as table settings, event coordination, and more. These can be
            useful if you want a more comprehensive service.
          </p>
          </div>
        </div> */}
        <div className="lg:w-2/5 p-4 sm:p-6 lg:p-8 mt-6 lg:mt-0">
        <h2 className="text-3xl font-bold mb-6 text-center ">
              Book Your Catering Service Now
            </h2>
            <p className="text-center text-gray-600 mb-4">
              We’re excited to make your event unforgettable! Please fill out
              the details below, and we’ll get back to you soon.
            </p>
          <form
            onSubmit={handleSubmit}
            className="space-y-8 bg-gray-100 p-8 rounded-lg shadow-lg"
          >


            <div>
              <label className="block mb-2 text-lg font-medium">Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder="Enter your full name"
                className="w-full px-4 py-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>

            <div>
              <label className="block mb-2 text-lg font-medium">Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Your email address"
                className="w-full px-4 py-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>

            <div>
              <label className="block mb-2 text-lg font-medium">Phone:</label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                placeholder="Your contact number"
                className="w-full px-4 py-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>

            <div>
              <label className="block mb-2 text-lg font-medium">
                Event Description:
              </label>
              <textarea
                name="eventDescription"
                value={formData.eventDescription}
                onChange={handleChange}
                required
                placeholder="Describe your event (e.g., wedding, birthday, corporate)"
                className="w-full px-4 py-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>

            <div>
              <label className="block mb-2 text-lg font-medium">
                Date of Event:
              </label>
              <input
                type="date"
                name="eventDate"
                value={formData.eventDate}
                onChange={handleChange}
                required
                className="w-full px-4 py-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-3 rounded-md font-semibold hover:bg-blue-700 transition-all shadow-md"
            >
              Submit
            </button>
          </form>
        </div>
      </div>

      {showPopup && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black
        bg-opacity-50 z-50"
        >
          <div className="popup-container bg-white p-8 rounded-lg text-center shadow-2xl">
            <div className="flex justify-center mb-4">
              <svg
                className="tick-icon w-20 h-20 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <h1 className="text-2xl font-bold text-gray-800">
              Details Submitted Successfully!
            </h1>
            <h2 className="text-xl font-bold text-gray-800">
              We will reach you shortly.
            </h2>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Catering;
