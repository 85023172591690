import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../Navbaar&Footer/Footer';
import Navbar from '../Navbaar&Footer/Navbar';
import hotel from "../Media/Loactions/hotel.jpeg";
import farmhouse from "../Media/Loactions/farmhouse.jpeg";
import resort from "../Media/Loactions/resort.jpeg";
import BanquetHalls from "../Media/Loactions/banquethalls.jpeg";

const Location = () => {
  const [formData, setFormData] = useState({
    state: '',
    venueType: '',
    phoneNumber: '',
    email: '',
    description: '',
    numberofguests: '',
  });
  const [locations, setLocations] = useState([]);
  const [showPopup, setShowPopup] = useState(true);

  const navigate = useNavigate();

  const sampleData = {
    Delhi: {
      indoor: [
        { name: 'Indoor Venue Delhi', capacity: 800, contact: '3456789012' },
        { name: 'Indoor Venue Noida', capacity: 600, contact: '2345678901' },
        { name: 'Indoor Venue Gurgaon', capacity: 550, contact: '1234567890' }
      ],
      outdoor: [
        { name: 'Outdoor Venue Delhi', capacity: 2000, contact: '0123456789' },
        { name: 'Outdoor Venue Noida', capacity: 1700, contact: '9012345678' },
        { name: 'Outdoor Venue Gurgaon', capacity: 1400, contact: '8901234567' }
      ]
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { state, venueType } = formData;
    if (state && venueType) {
      setLocations(sampleData[state]?.[venueType] || []);
      navigate('/contact');
    } else {
      alert('Please select both state and venue type.');
    }
  };

  const handlePopupSubmit = async (e) => {
    e.preventDefault();
    const { phoneNumber, email, description } = formData;
    if (phoneNumber && email && description) {
      try {
        const response = await fetch('http://localhost:5000/api/clients', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ phoneNumber, email, description }),
        });
        response.ok ? setShowPopup(false) : alert(`Error: ${(await response.json()).message}`);
      } catch (error) {
        alert(`Error: ${error.message}`);
      }
    } else {
      alert('Please fill in all the fields.');
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSelectVenue = (venueType) => {
    setFormData((prev) => ({ ...prev, venueType }));
  };

  const venueImages = {
    Hotel: hotel,
    Resort: resort,
    Farmhouse: farmhouse,
    BanquetHalls // Replace with the actual image path
  };

  return (
    <div className={showPopup ? 'blur-background' : ''}>
      <Navbar />
      <div className="max-w-4xl mx-auto p-6 mt-28 mb-32 bg-white rounded-lg shadow-md border-x-4 border-y-4 border-black">
        <h1 className="text-3xl font-bold text-center mb-4">Best Venue Available</h1>
        <form onSubmit={handleSubmit} className="mb-6">
          <div className="mb-4">
            <label htmlFor="state" className="block text-sm font-medium mb-2">Region</label>
            <select
              id="state"
              value={formData.state}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md"
            >
              <option value="">Select a region</option>
              <option value="Delhi">Delhi-NCR</option>              
              <option value="Delhi">Faridabad</option>
              <option value="Gurgaon">Gurgaon</option>
              <option value="Delhi">Noida</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Venue Type</label>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
              {['Hotel', 'Resort', 'Farmhouse', 'BanquetHalls'].map((type, index) => (
                <button
                  type="button"
                  key={index}
                  onClick={() => handleSelectVenue(type)}
                  className={`p-2 border rounded-md w-full h-full flex flex-col items-center justify-center ${formData.venueType === type ? 'border-blue-500' : ''}`}
                >
                  <img
                    src={venueImages[type]}
                    alt={type}
                    className="w-20 h-20 sm:w-32 sm:h-32 mb-2"
                  />
                  <p className="text-center">{type}</p>
                </button>
              ))}
            </div>
            {formData.venueType && <p className="mt-4 text-center">Selected Venue Type: {formData.venueType}</p>}
          </div>

          <div className="mb-4">
            <label htmlFor="numberofguests" className="block text-sm font-medium mb-2">Number of guests</label>
            <input 
              id="numberofguests"
              value={formData.numberofguests}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md"
            />
          </div>

          {/* Submit Button */}
          <div className="text-center">
            <button 
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
            >
              Submit
            </button>
          </div>
        </form>

        {locations.length > 0 && (
          <div className="bg-gray-100 p-4 rounded-md">
            <table className="w-full border-collapse">
              <thead>
                <tr>
                  <th className="border-b-2 py-2">S.No</th>
                  <th className="border-b-2 py-2">Name of Venue</th>
                  <th className="border-b-2 py-2">Maximum Capacity</th>
                  <th className="border-b-2 py-2">Contact Number</th>
                </tr>
              </thead>
              <tbody>
                {locations.map((location, index) => (
                  <tr key={index}>
                    <td className="border-b py-2 text-center">{index + 1}</td>
                    <td className="border-b py-2">{location.name}</td>
                    <td className="border-b py-2 text-center">{location.capacity}</td>
                    <td className="border-b py-2 text-center">{location.contact}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default Location;
