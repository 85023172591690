import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

const Blogs = () => {
    
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);

    // Use useCallback to memoize fetchBlogs function to prevent unnecessary re-creations
    const fetchBlogs = useCallback(async () => {
        try {
            // Simulated API call
            const blogData = [
                {
                    title: "How Do I Plan My Wedding?",
                    description: "Discover the latest trends in event planning that are set to make waves in 2024.",
                    image: "weddingblog.jpg",
                    link: "/weddingplan"
                },
                {
                    title: "Choose The Right Catering Service",
                    description: "Learn how to choose the perfect venue for your event, no matter the occasion.",
                    image: "/cateringblog.jpg",
                    link: "/cateringplan"
                },
                {
                    title: "Decide Best Theme",
                    description: "Explore tips and tricks for getting the most out of your event entertainment.",
                    image: "partyblog.jpg",
                    link: "/theme"
                }
            ];

            // Simulate delay
            setTimeout(() => {
                setBlogs(blogData);
                setLoading(false);
            }, 1000); // Reduced delay for better UX
        } catch (error) {
            console.error("Error fetching blog data:", error);
            setLoading(false); // Ensure loading state is updated on error
        }
    }, []);

    useEffect(() => {
        fetchBlogs();
    }, [fetchBlogs]);

    return (
        <div className=" m-0 p-10 ">
            <div className="mt-0 m-20 ml-0 w-full pt-10">
                <h1 className="text-3xl font-bold text-center">Explore Blogs</h1>
                <p className="text-gray-600 text-center mb-10">Read our blogs to enhance your knowledge </p>
                {loading ? (
                    <div className="text-sm text-gray-500 p-10 pl-32">
                        Loading blogs...
                    </div>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
                        {blogs.map((blog, index) => (
                            <div
                                key={index}
                                className="bg-white shadow-md rounded-lg overflow-hidden transform transition duration-500 hover:scale-105"
                            >
                                <img src={blog.image} alt={blog.title} className="w-full h-48 object-cover" />
                                <div className="p-4">
                                    <h2 className="text-xl font-semibold mb-2">{blog.title}</h2>
                                    <p className="text-gray-600 mb-4">{blog.description}</p>
                                    <Link className="text-blue-500 hover:underline" to={blog.link}>
                                        Read More
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default React.memo(Blogs); // Memoize Blogs component to prevent unnecessary re-renders
