import React, { useEffect, useRef } from 'react';
import Navbar from '../Navbaar&Footer/Navbar';
import Footer from '../Navbaar&Footer/Footer';

const vendors = [
  {
    name: 'Vendor 1',
    address: '123 Street, City, Country',
    photo: 'https://via.placeholder.com/100',
    contact: '123-456-7890',
  },
  {
    name: 'Vendor 2',
    address: '456 Avenue, City, Country',
    photo: 'https://via.placeholder.com/100',
    contact: '987-654-3210',
  },
  {
    name: 'Vendor 3',
    address: '789 Boulevard, City, Country',
    photo: 'https://via.placeholder.com/100',
    contact: '555-555-5555',
  },
  // Add more vendors as needed
];

const SMapComponent = () => {
  const mapRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://apis.mappls.com/advancedmaps/api/080798ab65ac3d686ffa9f64ea4c511a/map_sdk?layer=vector&v=3.0&callback=initMap1";
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);

    window.initMap1 = () => {
      const map = new window.mappls.Map(mapRef.current, {
        center: [28.5763322183388, 77.19656340729183], // Default center coordinates
        zoom: 16,
      });

      new window.mappls.Marker({
        position: [28.5763322183388, 77.19656340729183], // Marker coordinates
        map: map,
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <Navbar />
      <div className="flex flex-col lg:flex-row h-screen">
        {/* Left side: Vendor list */}
        <div className="lg:w-2/3 w-full p-4 lg:p-8 overflow-y-auto">
          <h2 className="text-xl font-bold mb-4">List of Vendors</h2>
          <div className="grid grid-cols-1 gap-4">
            {vendors.map((vendor, index) => (
              <div key={index} className="bg-white shadow-md rounded-lg p-4 flex items-center">
                <img src={vendor.photo} alt={vendor.name} className="w-16 h-16 rounded-full mr-4" />
                <div className="flex-grow">
                  <h3 className="text-lg font-semibold">{vendor.name}</h3>
                  <p className="text-sm text-gray-600">{vendor.address}</p>
                  <p className="text-sm text-gray-600">Contact: {vendor.contact}</p>
                </div>
                <button className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition">
                  View Profile
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Right side: Map */}
        <div className="lg:w-1/3 w-full h-96 lg:h-full">
          <div id="map" ref={mapRef} className="w-full h-full"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SMapComponent;
