import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import VendorOnboarding from './components/Register and Login/SignIn';
import VendorDetails from './components/Profile/Profile';
import LoginPage from './components/Register and Login/Login';
import VendorDetailst from './components/test';
import PaymentForm from './components/Payment/Payment';
import ChatBubble from './components/Profile/ChatBubble';
import SocialUsernames from './components/Social/Mediaccount';
import SocialInput from './components/Social/SocialRegister';
import WeddingCatalogue from './components/Wedding/Wedding';
import Location from './components/Wedding/Location';
import Pending from './components/Pending';
import Delhi from './components/Wedding/Delhi';
import PhotoFetcher from './components/testp';
import ContactForm from './components/Social/Contact';
import WeddingCateringPlan from './components/Wedding/Weddingplan';
import LegalAgreements from './components/Legal/Legal Agreements';
import PrivacyPolicy from './components/Legal/Privacypolicy';
import TermsAndConditions from './components/Legal/Terms&condition';
import About from './components/About/About';
import Catering from './components/Catering/Catering';
import Entertainment from './components/Entertainment/Entertainment';
import Crew from './components/Crew/Crew';
import InvitesAndGifts from './components/Invitesandgifts/Gifts';
import Decoration from './components/Decoration/Decoration';
import Gallery from './components/Gallery/Gallery';
import Blogs from './components/Blogs/Blogs';
import CateringServicePlan from './components/Blogs/Cateringplan';
import WeddingDecorationThemes from './components/Blogs/Theme';
import Construction from './components/Construction/Construction';
import ConstructionVendors from './components/Construction/ConstructionVendors';
import WeddingVendors from './components/Wedding/WeddingVendors';
import MapComponent from './components/Market/RajouriMarket';
import CCMapComponent from './components/Market/ChandniChowkMarket';
import KMapComponent from './components/Market/KhanMaeket';
import SMapComponent from './components/Market/SarojiniMarket';
import LMapComponent from './components/Market/LajpatMarket';
import MMapComponent from './components/Market/MBlockMarket';





const App = () => {
  return (
    <Router>
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        {/* to enable login signup and profile view */}
        {/* <Route path="/register" element={<VendorOnboarding />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/profile/:vendorId" element={<VendorDetails />} /> */}
        <Route path="/test" element={<VendorDetailst />} />
        <Route path="/payment" element={<PaymentForm />} />
        <Route path="/chatbubble" element={<ChatBubble />} />
        <Route path="/mediaccount" element={<SocialUsernames />} />
        <Route path="/socialregister" element={<SocialInput />} />
        <Route path="/wedding" element={<WeddingCatalogue />} />
        <Route path="/location" element={<Location/>} />
        <Route path="/pending" element={<Pending />} />
        <Route path="/delhiIndoor" element={<Delhi />} />
        <Route path="/testing" element={<PhotoFetcher />} />
        <Route path="/weddingplan" element={<WeddingCateringPlan />} />
        <Route path="/weddingvendors" element={<WeddingVendors />} />
        <Route path="/legal" element={<LegalAgreements />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/terms&condition" element={<TermsAndConditions />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/about" element={<About />} />
        <Route path="/catering" element={<Catering />} />
        <Route path="/entertainment" element={<Entertainment />} />
        <Route path="/crew" element={<Crew />} />
        <Route path="/gifts" element={<InvitesAndGifts />} />
        <Route path="/decoration" element={<Decoration />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/cateringplan" element={<CateringServicePlan />} />
        <Route path="/theme" element={<WeddingDecorationThemes />} />
        <Route path="/construction" element={<Construction />} />
        <Route path="/constructionvendors" element={<ConstructionVendors />} />
        <Route path="/rajourigardenmarket" element={<MapComponent />} />
        <Route path="/chandnichowkmkt" element={<CCMapComponent />} />
        <Route path="/khanmkt" element={<KMapComponent />} />
        <Route path="/sarojinimkt" element={<SMapComponent />} />
        <Route path="/lajpatmkt" element={<LMapComponent />} />
        <Route path="/mblockmkt" element={<MMapComponent />} />
      </Routes>
    </div>
  </Router>
  );
};

export default App;
