import React, { useEffect, useState } from "react";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";

const Gallery = () => {
  const [albums, setAlbums] = useState([]);

  const fetchImages = async () => {
    // Simulate fetching image data with a delay
    const imageAlbums = [
      {
        title: "Look at the Events Organised by Procydia",
        images: [
          { src: "weddingdecor1.jpeg", alt: "Wedding 1" },
          { src: "weddingdeco2.jpeg", alt: "Wedding 2" },
          { src: "weddingdecor3.jpeg", alt: "Wedding 3" },
          { src: "weddingdecor4.jpeg", alt: "Wedding 4" },
          { src: "weddingdecor5.jpeg", alt: "Wedding 4" },
          { src: "eventdecor1.jpeg", alt: "Wedding 4" },
          { src: "eventdecor2.jpeg", alt: "Wedding 4" },
          { src: "eventdecor3.jpeg", alt: "Wedding 4" }
        ],
      },
    ];
    
    return imageAlbums;
  };

  useEffect(() => {
    const loadImages = async () => {
      const albumsData = await fetchImages();
      setAlbums(albumsData);
    };

    loadImages();
  }, []);

  return (
    <>
      <Navbar />
      <div className="container mx-auto p-8">
        {albums.map((album, index) => (
          <div key={index} className="mb-12">
            <h2 className="text-center text-3xl font-bold mb-10 mt-16">{album.title}</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {album.images.map((image, imgIndex) => (
                <div key={imgIndex} className="overflow-hidden rounded-lg shadow-lg">
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="w-full h-60 object-cover transform hover:scale-105 transition-transform duration-300"
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
