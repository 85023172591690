import React, { useState, useEffect } from "react";
import axios from "axios";

function SocialUsernames() {
  const [usernames, setUsernames] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch social media usernames from the API
    const fetchUsernames = async () => {
      try {
        const response = await axios.get("http://localhost:5000/api/socials"); // Adjust the URL if your API endpoint is different
        setUsernames(response.data);
      } catch (err) {
        setError("Failed to fetch social media usernames");
        console.error(err);
      }
    };

    fetchUsernames();
  }, []); // Empty dependency array ensures the effect runs only once on mount

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h1>Social Media Usernames</h1>
      <ul>
        {usernames.map((username, index) => (
          <li key={index}>
            Instagram:{" "}
            <a
              href={`https://www.instagram.com/${username.Instagramusername}/`}
            >
              {" "}
              {username.Instagramusername}
            </a>
            , Facebook:{" "}
            <a href={`https://www.facebook.com/${username.Facebookusername}/`}>
              {" "}
              {username.Facebookusername}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SocialUsernames;
