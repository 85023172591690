import React, { useState } from "react";
import axios from "axios";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";
import { gsap } from "gsap";

const InvitesAndGifts = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    eventDescription: "",
    eventDate: "",
  });

  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post("https://procydia.onrender.com/api/gift", formData);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        // Redirect after 5 seconds
        window.location.href = "/";
      }, 5000);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="bg-white text-black p-4 justify-center sm:p-6 lg:p-10 w-full mt-8 flex flex-col lg:flex-row">
        {/* <div className="lg:w-3/5">
          <h1 className="text-3xl md:text-4xl font-bold text-center mb-6">Choosing the Perfect Invites and Gifts for Your Event</h1>
          <div className="max-w-4xl mx-auto">
          <p className="text-lg mb-6">
            Invites and gifts are a reflection of your event’s theme and the care you put into hosting. From setting the
            tone with beautiful invitations to sending guests home with memorable keepsakes, here's what to consider.
          </p>

          <h2 className="text-2xl font-bold mb-4">1. Event Theme and Design</h2>
          <p className="text-lg mb-6">
            Your invitations should match the theme and style of your event. Whether it's formal, casual, or whimsical,
            the design should give guests a preview of what to expect and set the tone for the event.
          </p>

          <h2 className="text-2xl font-bold mb-4">2. Personalization</h2>
          <p className="text-lg mb-6">
            Personalized invites and gifts add a special touch that makes your guests feel valued. Consider custom
            designs, messages, or even hand-written notes to make your event more personal and memorable.
          </p>

          <h2 className="text-2xl font-bold mb-4">3. Budget</h2>
          <p className="text-lg mb-6">
            Invites and gifts can be a significant part of your budget. Look for options that fit within your budget
            while still providing quality and a sense of occasion. DIY options can also be a cost-effective yet
            thoughtful choice.
          </p>

          <h2 className="text-2xl font-bold mb-4">4. Timing</h2>
          <p className="text-lg mb-6">
            Send out invites well in advance to ensure guests can plan accordingly. When it comes to gifts, consider
            the timing of when they'll be distributed during the event for maximum impact.
          </p>

          <h2 className="text-2xl font-bold mb-4">5. Eco-Friendly Options</h2>
          <p className="text-lg mb-6">
            Consider sustainable and eco-friendly options for both invites and gifts. Recycled paper for invitations
            and reusable or consumable gifts are thoughtful choices that align with a growing trend towards sustainability.
          </p>

          <h2 className="text-2xl font-bold mb-4">6. Vendor Reliability</h2>
          <p className="text-lg mb-6">
            Choose reliable vendors who can deliver high-quality invites and gifts on time. Check reviews and
            recommendations to ensure they have a good track record.
          </p>
          </div>
        </div> */}
        <div className="lg:w-2/5 p-4 sm:p-6 lg:p-8 mt-6 lg:mt-0">
          <h2 className="text-2xl font-bold mb-6 text-center">
            Book Your Invites And Gifts Service Now
          </h2>
          <p className="text-center text-gray-600 mb-4">
            We’re excited to make your event unforgettable! Please fill out the
            details below, and we’ll get back to you soon.
          </p>
          <form
            onSubmit={handleSubmit}
            className="space-y-6 bg-gray-100 p-6 sm:p-8 rounded-lg shadow-lg"
          >
            <div>
              <label className="block mb-2 text-lg font-medium">Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder="Enter your full name"
                className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <div>
              <label className="block mb-2 text-lg font-medium">Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Enter your email address"
                className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <div>
              <label className="block mb-2 text-lg font-medium">Phone:</label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                placeholder="Enter your phone number"
                className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <div>
              <label className="block mb-2 text-lg font-medium">
                Event Description:
              </label>
              <textarea
                name="eventDescription"
                value={formData.eventDescription}
                onChange={handleChange}
                required
                placeholder="Describe your event and gift preferences"
                className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                rows="4"
              />
            </div>

            <div>
              <label className="block mb-2 text-lg font-medium">
                Date of Event:
              </label>
              <input
                type="date"
                name="eventDate"
                value={formData.eventDate}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-3 rounded-md font-semibold hover:bg-blue-700 transition-all shadow-md"
            >
              Submit
            </button>
          </form>
        </div>
      </div>

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg text-center shadow-2xl">
            <div className="flex justify-center mb-4">
              <svg
                className="w-20 h-20 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <h1 className="text-2xl font-bold text-gray-800">
              Details Submitted Successfully!
            </h1>
            <h2 className="text-xl font-bold text-gray-800">
              We will reach you shortly.
            </h2>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default InvitesAndGifts;
