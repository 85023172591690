import React, { useState, useEffect, useCallback, Suspense } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";


const WeddingVendors = () => {
  const [industryLeaders, setIndustryLeaders] = useState([]);
  const [loading, setLoading] = useState(true); // Add a loading state

  // useCallback to memoize the fetchData function and prevent unnecessary re-creations
  const fetchData = useCallback(async () => {
    try {
      const response = await new Promise((resolve) =>
        setTimeout(() => {
          resolve([
            { id: 1, name: "Venue", subName: "Find the perfect place", photo: "venue.jpg", url: "/location", contact: "Contact us at +91-9818347406" },
            { id: 2, name: "Catering", subName: "Delicious meals", photo: "catering.jpg", url: "/catering", contact: "Contact us at +91-9818347406" },
            { id: 3, name: "Entertainment", subName: "Keep your guests engaged", photo: "entertainment.jpg", url: "/entertainment", contact: "Contact us at +91-9818347406" },
            { id: 4, name: "Decorations", subName: "Beautify your event", photo: "decoration.jpg", url: "/decoration", contact: "Contact us at +91-9818347406" },
            { id: 5, name: "Invites & Gifts", subName: "Memorable keepsakes", photo: "gift.jpg", url: "/gifts", contact: "Contact us at +91-9818347406" },
            { id: 6, name: "Crew", subName: "Find the best Team", photo: "crew.jpg", url: "/crew", contact: "Contact us at +91-9818347406" },
          ]);
        }, 300) // Shorten delay for faster loading
      );

      setIndustryLeaders(response);
      setLoading(false); // Stop loading once data is fetched
    } catch (error) {
      console.error("Error fetching industry leaders:", error);
      setLoading(false); // Stop loading even on error
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Avoid re-renders by memoizing mapped items
  const categoryCards = useCallback(() => {
    return industryLeaders.map((leader) => (
      <Link
        key={leader.id}
        to={leader.url}
        className="block overflow-hidden shadow-lg transform transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-xl"
      >
        <img
          src={leader.photo}
          alt={leader.name}
          className="w-full h-60 object-cover rounded-md"
          loading="lazy" // Keep lazy loading for images
        />
        <div className="p-4 bg-slate-100">
          <h3 className="text-lg font-bold text-black">{leader.name}</h3>
          <p className="text-sm text-gray-600">{leader.subName}</p>
          <p className="text-sm text-gray-500 mt-2">{leader.contact}</p>
        </div>
      </Link>
    ));
  }, [industryLeaders]);

  return (
    <div>
        <Navbar />
    <div className="bg-white text-black p-10 w-full mt-20 mb-20">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold">Explore Wedding Vendors</h2>
        <p className="text-gray-600">Choose from the top categories to enhance your event</p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {loading ? (
          <p className="text-sm text-gray-500 m-52 text-center">Loading categories...</p>
        ) : (
          categoryCards() // Use the memoized component rendering
        )}
      </div>
    </div>
    <Footer />
    </div>
  );
};

export default React.memo(WeddingVendors);
