import React from "react";
import Footer from "../Navbaar&Footer/Footer";
import Navbar from "../Navbaar&Footer/Navbar";

const PrivacyPolicy = () => {
  return (
    <>
    <Navbar />
    <div className="bg-gray-100 p-8 rounded-lg shadow-lg w-11/12 md:w-3/4 lg:w-2/3 mx-auto mt-20 mb-10">
      <h1 className="text-3xl font-bold text-center mb-6">Privacy Policy</h1>

      <div className="space-y-6">
        <section>
          <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
          <p>
            Procydia is committed to protecting the privacy of our users. This Privacy Policy explains how we
            collect, use, and disclose your personal information when you use our online platform.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-2">2. Information We Collect</h2>
          <ul className="list-disc list-inside">
            <li>
              <strong>Personal Information:</strong> We may collect personal information such as your name, email address, phone number, and business details when you register on our Platform or contact us.
            </li>
            <li>
              <strong>Usage Data:</strong> We automatically collect information about your interactions with our Platform, including IP addresses, browser type, pages viewed, and the time spent on our site.
            </li>
            <li>
              <strong>Cookies:</strong> We use cookies to enhance your experience, gather information about usage patterns, and personalize your interactions.
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-2">3. How We Use Your Information</h2>
          <ul className="list-disc list-inside">
            <li>
              <strong>To Provide Services:</strong> We use your personal information to operate, maintain, and improve our Platform.
            </li>
            <li>
              <strong>Communication:</strong> We may use your contact information to send you updates, newsletters, or promotional materials.
            </li>
            <li>
              <strong>Analytics:</strong> We analyze usage data to understand trends and improve our Platform.
            </li>
            <li>
              <strong>Legal Compliance:</strong> We may disclose your information if required by law or in response to legal requests.
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-2">4. Sharing Your Information</h2>
          <p>
            We do not sell, trade, or rent your personal information to third parties. We may share your information with trusted partners who assist us in operating our Platform, provided they agree to keep your information confidential.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-2">5. Data Security</h2>
          <p>
            We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-2">6. Your Choices</h2>
          <ul className="list-disc list-inside">
            <li>
              <strong>Opt-Out:</strong> You may opt out of receiving promotional emails by following the unsubscribe instructions in the email.
            </li>
            <li>
              <strong>Cookies:</strong> You can set your browser to refuse cookies or to alert you when cookies are being sent.
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-2">7. Changes to This Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-2">8. Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:connect@procydia.com" className="text-blue-500 underline">connect@procydia.com</a>.
          </p>
        </section>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default PrivacyPolicy;
