import React, { useState, useEffect } from "react";
import axios from "axios";

const PhotoFetcher = ({ photoId }) => {
  const [photoUrl, setPhotoUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPhoto = async () => {
      try {
        const response = await axios.get(
          `http://localhost:5000/api/vendor/profilepicture/66c037f0c8803ab8f4fc8a09`
        );
        setPhotoUrl(response.data.photoUrl);
      } catch (err) {
        setError("Error fetching photo");
      } finally {
        setLoading(false);
      }
    };

    fetchPhoto();
  }, [photoId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      {photoUrl ? (
        <img src={photoUrl} alt="Fetched from API" className="w-full h-auto" />
      ) : (
        <div>No photo available</div>
      )}
    </div>
  );
};

export default PhotoFetcher;
