import React from "react";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";

const WeddingCateringPlan = () => {
  return (
    <>
      <Navbar />
      <div className="p-10 mt-28 mb-10 max-w-7xl mx-auto bg-white border border-gray-200 rounded-lg shadow-lg space-y-20">
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-12">Let's Plan a Wedding!</h1>

        <section className="flex flex-col md:flex-row items-center md:items-start space-y-6 md:space-y-0">
          <div className="md:ml-10">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">Budget</h2>
            <ul className="list-disc pl-6 space-y-4 text-gray-700">
              <li><strong>Total Cost:</strong> Determine your budget for catering and ensure that the caterer can work within it.</li>
              <li><strong>Inclusions:</strong> Clarify what’s included in the price (e.g., food, service, setup, cleanup, taxes, gratuities).</li>
              <li><strong>Packages:</strong> Check if the caterer offers different packages that can be customized to fit your budget.</li>
            </ul>
          </div>
        </section>

        <section className="flex flex-col md:flex-row items-center md:items-start space-y-6 md:space-y-0">
          <div className="md:ml-10">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">Dietary Requirements</h2>
            <ul className="list-disc pl-6 space-y-4 text-gray-700">
              <li><strong>Special Diets:</strong> Ensure the caterer can accommodate special dietary needs, such as vegetarian, vegan, gluten-free, or allergy-friendly options.</li>
              <li><strong>Cultural/Religious Requirements:</strong> Consider any cultural or religious dietary requirements, like halal, kosher, or regional cuisines.</li>
            </ul>
          </div>
        </section>



        <section className="flex flex-col md:flex-row items-center md:items-start space-y-6 md:space-y-0">
          <div className="md:ml-10">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">Experience and Reputation</h2>
            <ul className="list-disc pl-6 space-y-4 text-gray-700">
              <li><strong>Experience with Weddings:</strong> Choose a caterer with a proven track record in catering weddings, as they’ll be familiar with the specific demands of the event.</li>
              <li><strong>Reviews and Testimonials:</strong> Read reviews and ask for testimonials from past clients.</li>
              <li><strong>References:</strong> Request references to speak directly with previous clients.</li>
            </ul>
          </div>
        </section>

  

        <section className="flex flex-col md:flex-row items-center md:items-start space-y-6 md:space-y-0">
          <div className="md:ml-10">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">Venue Compatibility</h2>
            <ul className="list-disc pl-6 space-y-4 text-gray-700">
              <li><strong>Venue Requirements:</strong> Confirm that the caterer is familiar with your venue and can meet its requirements (e.g., kitchen facilities, restrictions, layout).</li>
              <li><strong>Logistics:</strong> Discuss the logistics of food preparation and service at the venue, including setup and breakdown times.</li>
            </ul>
          </div>
        </section>



        <section className="flex flex-col md:flex-row items-center md:items-start space-y-6 md:space-y-0">
          <div className="md:ml-10">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">Flexibility and Responsiveness</h2>
            <ul className="list-disc pl-6 space-y-4 text-gray-700">
              <li><strong>Communication:</strong> Choose a caterer who is responsive, listens to your needs, and is willing to adjust plans as necessary.</li>
              <li><strong>Flexibility:</strong> Ensure the caterer is flexible with last-minute changes or special requests.</li>
            </ul>
          </div>
        </section>



        <section className="flex flex-col md:flex-row items-center md:items-start space-y-6 md:space-y-0">
          <div className="md:ml-10">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">Contract Details</h2>
            <ul className="list-disc pl-6 space-y-4 text-gray-700">
              <li><strong>Clear Agreement:</strong> Ensure all terms and conditions are clearly stated in the contract, including payment terms, cancellation policy, and any additional charges.</li>
              <li><strong>Review:</strong> Review the contract carefully before signing, and consider having a legal professional look it over.</li>
            </ul>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default WeddingCateringPlan;
