// frontend/src/components/ProductComponent.js
import React, { useEffect, useState } from "react";
import axios from "axios";

const ProductComponent = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await axios.get("/api/products");
        setProducts(res.data);
      } catch (err) {
        console.error(err.message);
        // Set sample data if no products are found
        setProducts([
          {
            _id: "sample1",
            name: "Sample Product 1",
            description: "This is a sample product description.",
            price: 19.99,
          },
          {
            _id: "sample2",
            name: "Sample Product 2",
            description: "Another sample product description.",
            price: 29.99,
          },
        ]);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className=" bg-red-100 py-10 mb-5 ml-0 pl-0">
      <div className="container mx-0 px-0">
        <div className="flex flex-wrap gap-4">
          {products.length === 0 ? (
            <p className="text-center col-span-full">
              No products found. Please try again later.
            </p>
          ) : (
            products.map((product) => (
              <div
                key={product._id}
                className="bg-red-500 border rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-300 flex flex-col w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5"
              >
                <h2 className="text-xl font-semibold mb-2 text-white">
                  {product.name || "product name"}
                </h2>
                <p className="text-gray-200 mb-4">{product.description}</p>
                <p className="text-gray-100 font-bold mb-4">${product.price}</p>
                <button className="w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-300">
                  Buy Now
                </button>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductComponent;
