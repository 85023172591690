import React from "react";
import { Link } from "react-router-dom";
import { FaLinkedin, FaInstagram, FaGithub, FaFacebook, FaYoutube, FaPhoneSquareAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";

const Footer = () => {
  const d = new Date();
  let year = d.getFullYear();
  
  return (
    <footer className="bg-gray-900 text-muted-foreground py-10 px-6 md:py-20 md:px-20">
      <div className="flex flex-col lg:flex-row gap-16 lg:gap-44 text-white">
        <div className="flex flex-col mb-8 lg:mb-0">
          <h5 className="font-bold mb-4 text-xl lg:text-2xl">GREEN LOBBY ENTERPRISES</h5>
          <p className="text-sm lg:text-base">E-478 BASEMENT REAR SIDE, <br /> GREATER KAILASH- II </p>
          <p className="text-sm lg:text-base">NEAR MTNL OFFICE, DELHI, South Delhi, Delhi</p>
          <p className="text-sm lg:text-base">India, 110048</p>
        </div>

        <div className="flex flex-col mb-8 lg:mb-0">
          <h5 className="font-bold mb-4 text-lg lg:text-xl">Company</h5>
          <Link to="/about" className="hover:text-primary mb-1 text-sm lg:text-base">
            About
          </Link>
          {/* <Link to="/about" className="hover:text-primary mb-1 text-sm lg:text-base">
            Features
          </Link>
          <Link to="/about" className="hover:text-primary mb-1 text-sm lg:text-base">
            Works
          </Link> */}
          <Link to="/Pending" className="hover:text-primary text-sm lg:text-base">
            Career
          </Link>
        </div>

        <div className="flex flex-col mb-8 lg:mb-0">
          <h5 className="font-bold mb-4 text-lg lg:text-xl">Help</h5>
          {/* <Link to="/contact" className="hover:text-primary mb-1 text-sm lg:text-base">
            Customer Support
          </Link> */}
          <Link to="/contact" className="hover:text-primary mb-1 text-sm lg:text-base">
            Contact Us
          </Link>
          {/* <Link to="/contact" className="hover:text-primary mb-1 text-sm lg:text-base">
            Complaints
          </Link> */}
          <Link to="/privacypolicy" className="hover:text-primary text-sm lg:text-base">
            Website Terms
          </Link>
        </div>

        <div className="flex flex-col mb-8 lg:mb-0">
          <h5 className="font-bold mb-4 text-lg lg:text-xl">Legal</h5>
          {/* <Link to="/privacypolicy" className="hover:text-primary mb-1 text-sm lg:text-base">
            Legal Agreements
          </Link>
          <Link to="/privacypolicy" className="hover:text-primary mb-1 text-sm lg:text-base">
            Terms & Conditions
          </Link> */}
          <Link to="/privacypolicy" className="hover:text-primary text-sm lg:text-base">
            Privacy Policy
          </Link>
        </div>

        <div className="flex flex-col">
          <h5 className="font-bold mb-4 text-lg lg:text-xl">Socials</h5>
          <div className="flex space-x-3 lg:space-x-4 mb-2">
            <a href="https://in.linkedin.com/company/procydia" className="hover:text-primary" aria-label="LinkedIn">
              <FaLinkedin size={24} />
            </a>
            <a href="https://www.instagram.com/procydia/" className="hover:text-primary" aria-label="Instagram">
              <FaInstagram size={24} />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100092634392706" className="hover:text-primary" aria-label="Facebook">
              <FaFacebook size={24} />
            </a>
            <a href="https://www.youtube.com/@proceedwiththeidea" className="hover:text-primary" aria-label="YouTube">
              <FaYoutube size={24} />
            </a>
          </div>
          <div className="text-sm lg:text-base">
            <p className="font-semibold text-lg lg:text-xl">Sanchit Gupta</p>
            <p>Founder</p>
            <div className="flex items-center gap-2 mt-2">
              <IoIosMail size={18} />
              <a href="mailto:connect@procydia.com" className="hover:underline">
                connect@procydia
              </a>
            </div>
            <div className="flex items-center gap-2 mt-1">
              <FaPhoneSquareAlt size={18} />
              <a href="tel:+919818347406" className="hover:underline">
                9818347406
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="border-t border-gray-700 my-8"></div> {/* Horizontal line */}
      
      <div className="flex justify-center text-white">
        <p className="text-xs lg:text-sm text-center">
          GREEN LOBBY ENTERPRISES © {year}, All Rights Reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
