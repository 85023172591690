import React from "react";
import Navbar from "../Navbaar&Footer/Navbar";
import Footer from "../Navbaar&Footer/Footer";

const WeddingDecorationThemes = () => {
  return (
    <>
      <div>
        <Navbar />
      </div>
      <div className="p-10 mt-28 mb-10 max-w-7xl mx-auto bg-white border border-gray-200 rounded-lg shadow-lg space-y-20">
        <h1 className="text-4xl font-extrabold text-center text-gray-900 mb-12">
          Wedding Decoration Themes
        </h1>

        <section className="flex flex-col md:flex-row items-center md:items-start space-y-6 md:space-y-0">

          <div className="md:ml-10">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">Rustic Theme</h2>
            <p className="text-gray-700 mb-4">
              The rustic theme is perfect for couples who love nature and a
              down-to-earth vibe. This theme often includes elements like
              wooden furniture, burlap, mason jars, and wildflowers, creating a
              charming, vintage atmosphere.
            </p>
            <ul className="list-disc pl-6 space-y-4 text-gray-700">
              <li>
                <strong>Color Palette:</strong> Earthy tones, soft pastels, and
                neutral colors.
              </li>
              <li>
                <strong>Key Elements:</strong> Wood accents, string lights,
                wildflowers, and natural fabrics.
              </li>
              <li>
                <strong>Ideal Venue:</strong> Barns, outdoor settings, vineyards.
              </li>
            </ul>
          </div>
        </section>

        <section className="flex flex-col md:flex-row items-center md:items-start space-y-6 md:space-y-0">
        <div className="md:ml-10">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">
              Elegant Black and White
            </h2>
            <p className="text-gray-700 mb-4">
              The classic black and white theme exudes elegance and
              sophistication. This timeless color scheme is perfect for couples
              who want a chic and refined wedding.
            </p>
            <ul className="list-disc pl-6 space-y-4 text-gray-700">
              <li>
                <strong>Color Palette:</strong> Black, white, and possibly a
                metallic accent like gold or silver.
              </li>
              <li>
                <strong>Key Elements:</strong> Monochrome decorations, sleek
                lines, candles, and crystal accents.
              </li>
              <li>
                <strong>Ideal Venue:</strong> Luxury hotels, ballrooms, urban
                settings.
              </li>
            </ul>
            </div>
    
        </section>

        <section className="flex flex-col md:flex-row items-center md:items-start space-y-6 md:space-y-0">

          <div className="md:ml-10">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">Bohemian Theme</h2>
            <p className="text-gray-700 mb-4">
              The bohemian theme is all about free-spiritedness and creativity.
              It incorporates eclectic and vintage elements, bold patterns, and
              a mix of natural and vibrant colors.
            </p>
            <ul className="list-disc pl-6 space-y-4 text-gray-700">
              <li>
                <strong>Color Palette:</strong> Bold hues like deep reds,
                oranges, and purples, mixed with natural tones.
              </li>
              <li>
                <strong>Key Elements:</strong> Macramé, dreamcatchers,
                mismatched chairs, and wild, abundant flowers.
              </li>
              <li>
                <strong>Ideal Venue:</strong> Outdoor gardens, beach settings,
                open fields.
              </li>
            </ul>
          </div>
        </section>

      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default WeddingDecorationThemes;
