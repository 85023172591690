import React, { useState } from "react";
import axios from "axios";

const SocialInput = ({ vendorId, onSocialAdded }) => {
  const [instagramUsername, setInstagramUsername] = useState("");
  const [facebookUsername, setFacebookUsername] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `http://localhost:5000/api/vendors/${vendorId}/social`,
        {
          Instagramusername: instagramUsername,
          Facebookusername: facebookUsername,
        }
      );

      setMessage("Social media usernames added successfully!");
      setInstagramUsername("");
      setFacebookUsername("");
      if (onSocialAdded) {
        onSocialAdded(response.data.social); // Notify parent component
      }
    } catch (err) {
      console.error(err);
      setMessage("Failed to add social media usernames");
    }
  };

  return (
    <div>
      <h2>Enter Social Media Usernames</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="instagram">Instagram Username:</label>
          <input
            type="text"
            id="instagram"
            value={instagramUsername}
            onChange={(e) => setInstagramUsername(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="facebook">Facebook Username:</label>
          <input
            type="text"
            id="facebook"
            value={facebookUsername}
            onChange={(e) => setFacebookUsername(e.target.value)}
          />
        </div>
        <button type="submit">Submit</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default SocialInput;
